import "./app.css";

function App() {
  return (
    <div className="container">
        <div class="d-flex flex-sm-row flex-column justify-content-between">
          <div class="bd-highlight align-self-center">
            <div className="pr-3">
              <div class="intro">
                <p>
                  An experienced engineer vast with other skills including leadership and management; 
                  passionate about software development, open-source, mentorship and entrepreneurship.
                </p>
              </div>
              <p class="languages">
                &#8212; <b>System:</b> Linux, Docker, C / C++ <b>Backend:</b> Java, Python (<span class="font-sm">Django</span>), GO, Dart, Node.js, 
                PHP <b>Frontend:</b> JavaScript (<span class="font-sm">Angular</span>), <b>Mobile:</b> Flutter
              </p>
            </div>

            <div className="pr-3 academic">
              <p class="languages"> Master of Science (MS), Computational Intelligence</p>
            </div>
            
          </div>
          <div class="bd-highlight avatar">
            <img src="assets/img/shimanyi.png" width="250px" class="rounded-circle img-responsive"/>
          </div>
        </div>
      <div className="links">
        <a
          href="https://twitter.com/osvalentino"
          target="_blank"
          rel="noreferrer"
        >
         <i class="bi-twitter"></i>
        </a>

        <a
          href="https://linkedin.com/in/shimanyi"
          target="_blank"
          rel="noreferrer"
        >
           <i class="bi-linkedin"></i>
        </a> 

        <a
          href="https://github.com/shimanyi"
          target="_blank"
          rel="noreferrer"
        >
           <i class="bi-github"></i>
        </a> 
        <span class="divider"></span>
        <span class="email-address">v@shimanyi.co</span>
      </div>
      <div class="copyright">
          <span>&copy; 2021</span>
      </div>
    </div>
  );
}

export default App;
